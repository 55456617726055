import { useState, useEffect } from 'react';
import { ContentArea } from '@megafon/ui-core';
import { Select } from '@megafon/ui-core';
import { TextField } from '@megafon/ui-core';
import './Filters.css';

const Filters = ({ filters, onFilterChange, dataForFilters }) => {
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onFilterChange(name, value);
 
   
  };

  const handleSelectOperator = (_e, data, ) => {
    const name = 'operator';
 
    if (!data) {
      onFilterChange(name, ''); 
        return;
    } 
    onFilterChange(name, data.value); 
   
};

  const handleSelectDates = (_e, data, ) => {
    const name = 'date';
 
    if (!data) {
      onFilterChange(name, ''); 
        return;
    } 
    onFilterChange(name, data.value); 
   
};
  const DemoSelectWrapperStyle = { width: '264px',padding: '20px' };
  const [operatorsList, setOperatorsList] = useState([]);
  const [dateList, setDatesList] = useState([]);

  useEffect(() => {
      const {operators, dates, tarifs } = dataForFilters;
 
      if (Array.isArray(operators)) setOperatorsList(operators)
      if (Array.isArray(dates)) setDatesList(dates)
  }, [dataForFilters]);

  return (
    <ContentArea>
      <div className="filters"> 
        <div style={DemoSelectWrapperStyle}>
            <Select
              label="Даты"
              name="date"
              onSelect={handleSelectDates}
              placeholder="Даты"
              items={dateList}
              currentValue={filters.date}
            /> 
          </div> 
            <div style={DemoSelectWrapperStyle}>
            <Select
                label="Провайдеры"
                name="operator"
                onSelect={handleSelectOperator}
                placeholder="Провайдеры"
                items={operatorsList}
                currentValue={filters.operator}
              /> 
            </div>
      </div>
      
      <div className="filters"> 
      <div style={DemoSelectWrapperStyle}>
        <TextField
          type="text"
          name="nbn_region"
          value={filters.nbn_region}
          onChange={handleInputChange}
          placeholder="Регион"
        /> 
      </div>
      <div style={DemoSelectWrapperStyle}>
      <TextField
        type="text"
        name="service_name"
        value={filters.service_name}
        onChange={handleInputChange}
        placeholder="Тип услуги"
      /> 
      </div> 
      <div style={DemoSelectWrapperStyle}>
      <TextField
        type="text"
        name="tariff"
        value={filters.tariff}
        onChange={handleInputChange}
        placeholder="Тип тарифа"
      /> 
      </div>
      <div style={DemoSelectWrapperStyle}>
      <TextField
        type="text"
        name="location"
        value={filters.location}
        onChange={handleInputChange}
        placeholder="Локация"
      /> 
       </div> 
    </div> 
 
    </ContentArea>
  );
};

export default Filters;
