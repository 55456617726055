class Api {
    constructor(baseUrl, headers) {
      this._headers = headers;
      this._baseUrl = baseUrl;
    }
    
    _request(url, options) {
      return fetch(url, options).then(this.resolveFetch)
    }

    resolveFetch(res) {
      if (res.ok) { 
        return res.json();
      } else {
        return Promise.reject(`Ошибка: ${res.status}`);
      }
    }

   
    getCards( ) {     
        return this._request(`${this._baseUrl}/cards`, {
          credentials: 'include',
          headers: this._headers
        });
    }  
   
    getDates( ) {     
        return this._request(`${this._baseUrl}/dates`, {
          credentials: 'include',
          headers: this._headers
        });
    }  
 
    getOperators( ) {     
        return this._request(`${this._baseUrl}/companies`, {
          credentials: 'include',
          headers: this._headers
        });
    }  

    searchCards(data) {
        return this._request(`${this._baseUrl}/cards`, {
          method: "POST",
          body: JSON.stringify(data),
          credentials: 'include',
          headers: this._headers
        });
    }
 
}

const headers = {
    "Content-Type": "application/json"
};
  
const baseUrl = '/api';

export const api = new Api(baseUrl, headers);
