import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import {api} from '../../utils/Api';
import HomePage from '../../pages/HomePage/HomePage';
import './App.css';
import "@megafon/ui-core/styles/colors.css";

const App = () => {


  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [deafultOperators, setDeafultOperators] = useState([{
    value: '',
    title: 'Все провайдеры',
}]);
  const [deafultDates, setDeafultDates] = useState([{
    value: '',
    title: 'Сегодня',
}]);
  const [dataForFilters, setdataForFilters] = useState({
    operators: deafultOperators,
    dates: [ ],
    tarifs: [ ]
  });

  const [cards, setCards] = useState([]);
  // Здесь можно добавить состояния для хранения других данных, если необходимо
 
  const handleFilterChange = (filter, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filter]: value
    }));
 
  };
 
  const handleGetOperators = useCallback(() => {   
   
      api.getOperators().then((operators) => {
        const newOperators = []; 
        operators.forEach(operator => {
          newOperators.push({
            value: operator.name,
            title: operator.name, 
            })
        }); 
        setDeafultOperators(newOperators); 
      }).catch((err) => { 
          console.log(err);
      }).finally(() => { 
      
      });  
  }, [ ]);
 
  const handleGetDates = useCallback(() => {   
   
      api.getDates().then((dates) => {
        const newDates = []; 
        dates.forEach(dt => {
          newDates.push({
            value: dt.date,
            title: dt.date, 
            })
        }); 
        setDeafultDates(newDates); 
      }).catch((err) => { 
          console.log(err);
      }).finally(() => { 
      
      });  
  }, [ ]);

  const handleGetCards = useCallback(() => {   
    setIsLoading(true);    
      api.searchCards(filters).then((savedCards) => {
        setCards(savedCards);
      }).catch((err) => { 
          console.log(err);
      }).finally(() => { 
        setIsLoading(false);
      });  
  }, [filters]);

 

  useEffect(() => { 
    handleGetOperators()
    handleGetDates()
  }, [handleGetDates, handleGetOperators]);
 
  useEffect(() => { 
    const newFilerData = {
      operators: deafultOperators,
      dates: deafultDates,
      tarifs: [ ]
    }
    setdataForFilters(newFilerData) 
  }, [deafultOperators, deafultDates]);

 

    useEffect(() => { 
      handleGetCards();
    }, [ filters, handleGetCards]);
  
  return ( 
      <div className="app"> 
        <Routes>
          <Route exact path="/"
            element={
              <HomePage isLoading={isLoading} cards={cards} filters={filters} onFilterChange={handleFilterChange} dataForFilters={dataForFilters}/>
          } />
          {/* Добавьте другие маршруты и соответствующие компоненты, если необходимо */}
        </Routes>
      </div> 
  );
};

export default App;

