import './Card.css';
const Card = ({ data }) => {
    return (
      <div className="card">
        <h3 title='оператор'>{data.operator}</h3>
        <p title='нбн регион'> { data.nbn_region}</p>
        <p title='филиал мф'> { data.mf_branch}</p> 
        <p title='локация'> { data.location}</p>
        <p title='наименование услуги'> { data.service_name}</p>
        <p title='тариф'> { data.tariff}</p>
        <p title='тип тарифа'> { data.tariff_type}</p>
        <p title='Скорость проводного интернета'> <strong>{ data.wiredinternetspeed}</strong> МБит/с</p>
        <p title='кол-во каналов'> {data.numberofchannels!=='' ? <><strong>{ data.numberofchannels}</strong> каналов</> :'-'}</p>
        <p title='кол-во минут'> {data.numberofminutes!=='' ? <><strong>{ data.numberofminutes}</strong>минут</> :'-'}</p>
        <p title='кол-во смс'> {data.numberofsms!=='' ? <><strong>{ data.numberofsms}</strong> SMS</> :'-'}</p>
        <p title='моб интернет кол-во'> {data.mobileinternetamount!=='' ? <> <strong>{ data.mobileinternetamount}</strong> ГБ</> :'-'}</p>
        <p title='абонентская плата'>AБ плата <strong>{ data.monthly_fee}</strong> ₽</p>
        <p title='абон плата со скидкой'>{data.discounted_fee!=='' ? <>AБ плата со скидкой <strong>{ data.discounted_fee}</strong> ₽</> :'-'}</p>
        <p title='стоимость аренды роутера'>{data.routerrentalcost!=='' ? <> Роутер <strong>{ data.routerrentalcost}</strong> ₽</> :'-'}</p>
        <p title='стоимость аренды приставки'>{data.settop_boxrentalcost!=='' ? <> Приставка <strong>{ data.settop_boxrentalcost}</strong> ₽</> :'-'}</p>
        <p title='общая стоимость'>Total <strong>{ data.total_cost}</strong> ₽</p>
        <p title='Условия акции'>{data.promotion_conditions!=='' ? <> <strong>Условия акции</strong> { data.promotion_conditions}</> :''}</p>
 
      </div>
    );
  };
  
  export default Card;
  