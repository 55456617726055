import React from 'react';
import Filters from '../../components/Filters/Filters';
import Card from '../../components/Card/Card'; 
import Preloader from '../../components/Preloader/Preloader';
import { Carousel, ContentArea } from "@megafon/ui-core";



const HomePage = ({isLoading, filters, onFilterChange, cards, dataForFilters}) => {
 
  const blockStyle = { padding: '20px 0' };
 

  const sliderConfig ={
      0: { slidesPerView: "auto", spaceBetween: 16 },
      1024: { slidesPerView: "auto", spaceBetween: 20 },
      1280: { slidesPerView: "auto", spaceBetween: 20 }
  }
 
  return (
    <div className="home-page">
      <h1>Мониторинг конкурентов</h1>
   
      <Filters filters={filters} onFilterChange={onFilterChange} dataForFilters={dataForFilters}/>

      <ContentArea outerBackgroundColor="spbSky1" >
        <div style={blockStyle}>
        {isLoading ? ( 
           <Preloader />  
          ) :  (
            <Carousel slidesSettings={sliderConfig} > 

            {cards.map((card) => (
                <Card key={card.id} data={card} />   
              ))}  
          
            </Carousel>
          )}
            
        </div>
        </ContentArea> 
    </div>
  );
};

export default HomePage;
